// ScrollContainer.js - 基类组件
import React from 'react';

import styles from './view/lanhu_15hezuoyuanxiao/assets/indexResponse.module.css';
import Header from './components/header.jsx';
import Footer from './components/footer.jsx';
const universitiesList =
    [
        {
            "country": "英国",
            "country_code": "GB",
            "universities": [
                {
                    "university_code": "UCL",
                    "name": "伦敦大学学院",
                    "background_image": "UK-London.png",
                    "introduction": "英国顶尖研究型大学，医学与人文并重",
                    "isHasLogo": true,
                },
                {
                    "university_code": "EDIN",
                    "name": "爱丁堡大学",
                    "background_image": "UK-Edinburgh.png",
                    "introduction": "苏格兰名校，医学与历史学科著称",
                    "isHasLogo": true,
                },
                {
                    "university_code": "UoM",
                    "name": "曼彻斯特大学",
                    "background_image": "UK-Manchester.png",
                    "introduction": "红砖大学，理工科与商科并重",
                    "isHasLogo": true,
                },
                {
                    "university_code": "UoB",
                    "name": "伯明翰大学",
                    "background_image": "UK-Birmingham.png",
                    "introduction": "英国顶尖学府，以钟楼为标志",
                    "isHasLogo": true,
                },
                {
                    "university_code": "Leeds",
                    "name": "利兹大学",
                    "background_image": "UK-Leeds.png",
                    "introduction": "红砖大学，以商学和工程见长",
                    "isHasLogo": true,
                },
                {
                    "university_code": "Glasgow",
                    "name": "格拉斯哥大学",
                    "background_image": "UK-Glasgow.png",
                    "introduction": "苏格兰最古老大学，医学与神学闻名",
                    "isHasLogo": true,
                },
                {
                    "university_code": "Sheffield",
                    "name": "谢菲尔德大学",
                    "background_image": "UK-Sheffield.jpg",
                    "introduction": "红砖大学，工程与材料科学领先",
                    "isHasLogo": true,
                },
                {
                    "university_code": "Newcastle",
                    "name": "纽卡斯尔大学",
                    "background_image": "SING-Newcastle.jpg",
                    "introduction": "英格兰东北部名校，医学与工程并重",
                    "isHasLogo": true,
                },
                {
                    "university_code": "Lancaster",
                    "name": "兰卡斯特大学",
                    "background_image": "UK-Lancaster.jpg",
                    "introduction": "英国顶尖商学院，以管理学著称",
                    "isHasLogo": true,
                }
            ]
        },
        {
            "country": "美国",
            "country_code": "US",
            "universities": [
                {
                    "university_code": "UC Davis",
                    "name": "加州大学戴维斯分校",
                    "background_image": "USA-California.jpg",
                    "introduction": "美国公立常春藤，农业与兽医领先",
                    "isHasLogo": true,
                },
                {
                    "university_code": "UCI",
                    "name": "加州大学欧文分校",
                    "background_image": "USA-California.jpg",
                    "introduction": "加州顶尖公立大学，计算机与医学突出",
                    "isHasLogo": true,
                },
                {
                    "university_code": "UCSB",
                    "name": "加州大学圣巴巴拉分校",
                    "background_image": "USA-California2.jpg",
                    "introduction": "海滨研究型大学，环境科学与工程强",
                    "isHasLogo": true,
                },
                {
                    "university_code": "CWRU",
                    "name": "凯斯西储大学",
                    "background_image": "USA-CWRU.jpg",
                    "introduction": "美国顶尖私立大学，医学与工程闻名",
                    "isHasLogo": true,
                },
                {
                    "university_code": "Purdue",
                    "name": "普渡大学",
                    "background_image": "USA-Purdue.png",
                    "introduction": "美国工科强校，航天与工程领先",
                    "isHasLogo": true,
                },
                {
                    "university_code": "OSU",
                    "name": "俄亥俄州立大学",
                    "background_image": "USA- Ohio.jpeg",
                    "introduction": "美国最大公立大学，商学与体育强",
                    "isHasLogo": true,
                },
                {
                    "university_code": "PSU",
                    "name": "宾夕法尼亚州立大学",
                    "background_image": "USA-Pennsylvania.png",
                    "introduction": "美国顶尖公立大学，工程与农业突出",
                    "isHasLogo": true,
                }
            ]
        },
        {
            "country": "澳大利亚",
            "country_code": "AU",
            "universities": [
                {
                    "university_code": "USYD",
                    "name": "悉尼大学",
                    "background_image": "AU-SYDNEY.png",
                    "introduction": "澳大利亚最古老大学，医学与法律强",
                    "isHasLogo": true,
                },
                {
                    "university_code": "UNSW",
                    "name": "新南威尔士大学",
                    "background_image": "AU-NewSouthWales.png",
                    "introduction": "澳大利亚顶尖理工大学，工程与商科强",
                    "isHasLogo": true,
                },
                {
                    "university_code": "ANU",
                    "name": "澳大利亚国立大学",
                    "background_image": "AU-ANU.png",
                    "introduction": "澳大利亚顶尖研究型大学，政治与科学强",
                    "isHasLogo": true,
                },
                {
                    "university_code": "UniMelb",
                    "name": "墨尔本大学",
                    "background_image": "AU-Melbourne.jpeg",
                    "introduction": "澳大利亚顶尖大学，医学与法律闻名",
                    "isHasLogo": true,
                },
                {
                    "university_code": "UQ",
                    "name": "昆士兰大学",
                    "background_image": "AU-UQ.jpg",
                    "introduction": "澳大利亚顶尖公立大学，农业与环境强",
                    "isHasLogo": true,
                },
                {
                    "university_code": "Monash",
                    "name": "莫纳什大学",
                    "background_image": "AU-Monash.png",
                    "introduction": "澳大利亚规模最大大学，医学与商科强",
                    "isHasLogo": true,
                }
            ]
        },
        {
            "country": "新加坡",
            "country_code": "SG",
            "universities": [
                {
                    "university_code": "UoB-SG",
                    "name": "伯明翰大学新加坡分校",
                    "background_image": "UK-Birmingham.png",
                    "introduction": "新加坡分校，继承英国本校学术传统",
                    "isHasLogo": true,
                },
                {
                    "university_code": "Curtin-SG",
                    "name": "科廷大学新加坡分校",
                    "background_image": "SING-Curtin.png",
                    "introduction": "新加坡分校，提供应用型课程",
                    "isHasLogo": true,
                },
                {
                    "university_code": "Newcastle-SG",
                    "name": "纽卡斯尔大学新加坡分校",
                    "background_image": "SING-Newcastle.jpg",
                    "introduction": "新加坡分校，医学与工程并重",
                    "isHasLogo": true,
                },
                {
                    "university_code": "UCD-SG",
                    "name": "都柏林大学学院新加坡分校",
                    "background_image": "SING-Ucd.png",
                    "introduction": "新加坡分校，商科与计算机强",
                    "isHasLogo": true,
                },
                {
                    "university_code": "UOW-SG",
                    "name": "伍伦贡大学新加坡分校",
                    "background_image": "SING-UOW-SG.png",
                    "introduction": "新加坡分校，工程与IT领域突出",
                    "isHasLogo": true,
                },
                {
                    "university_code": "RMIT-SG",
                    "name": "皇家墨尔本理工大学新加坡校区",
                    "background_image": "SING-RMIT-SG.png",
                    "introduction": "新加坡分校，艺术与设计领先",
                    "isHasLogo": true,
                }
            ]
        },
        {
            "country": "中国香港",
            "country_code": "HK",
            "universities": [
                {
                    "university_code": "PolyU",
                    "name": "香港理工大学",
                    "background_image": "HK-PolyU.png",
                    "introduction": "香港顶尖理工大学，设计与工程强",
                    "isHasLogo": true,
                },
                {
                    "university_code": "HKBU",
                    "name": "香港浸会大学",
                    "background_image": "HK-HKBU.png",
                    "introduction": "香港私立大学，传媒与中医突出",
                    "isHasLogo": true,
                },
                {
                    "university_code": "Lingnan",
                    "name": "香港岭南大学",
                    "background_image": "HK-Lingnan.png",
                    "introduction": "香港博雅教育代表，人文与社科强",
                    "isHasLogo": true,
                },
                {
                    "university_code": "HKMU",
                    "name": "香港都会大学",
                    "background_image": "HK-HKMU.png",
                    "introduction": "香港公立大学，开放教育与商科强",
                    "isHasLogo": true,
                },
                {
                    "university_code": "EdUHK",
                    "name": "香港教育大学",
                    "background_image": "HK-EdUHK.jpeg",
                    "introduction": "香港唯一师范大学，教育研究领先",
                    "isHasLogo": true,
                },
                {
                    "university_code": "HKSYU",
                    "name": "香港树仁大学",
                    "background_image": "HK-HKSYU.jpg",
                    "introduction": "香港私立大学，法律与商科突出",
                    "isHasLogo": true,
                }
            ]
        },
        {
            "country": "日本",
            "country_code": "JP",
            "universities": [
                {
                    "university_code": "Kyoto-U",
                    "name": "京都大学",
                    "background_image": "JAPAN-Kyoto.png",
                    "introduction": "日本顶尖大学，樟树与钟楼象征学术",
                    "isHasLogo": true,
                },
                {
                    "university_code": "Waseda",
                    "name": "早稻田大学",
                    "background_image": "JAPAN-Waseda.png",
                    "introduction": "日本私立双雄之一，政治与经济强",
                    "isHasLogo": true,
                },
                {
                    "university_code": "Keio",
                    "name": "庆应义塾大学",
                    "background_image": "JAPAN-Keio.png",
                    "introduction": "日本私立顶尖大学，商学与医学强",
                    "isHasLogo": true,
                },
                {
                    "university_code": "Nagoya-U",
                    "name": "名古屋大学",
                    "background_image": "JAPAN-Nagoya.png",
                    "introduction": "日本顶尖国立大学，医学与工程强",
                    "isHasLogo": true,
                },
                {
                    "university_code": "Osaka-U",
                    "name": "大阪大学",
                    "background_image": "JAPAN-OsakaU.jpg",
                    "introduction": "日本顶尖国立大学，医学与理学强",
                    "isHasLogo": true,
                }
            ]
        }
    ];
class Universities extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data_list: [],
            colors: ["#d3292f", "#004c23", "#1a3a59", "#243e8d", "#69b3e7"]
        };
        universitiesList.map(countryItem => {
            countryItem.universities.map(item => {
                this.state.data_list.push({ ...item, country: countryItem.country, country_code: countryItem.country_code })
            })
        })
    }


    render() {
        let { data_list } = this.state;
        return (
            <div id="mainwrap" ref={this.scrollContainer} style={{
                height: '100vh',
                overflowY: 'scroll',
                scrollSnapType: 'y mandatory',
                scrollBehavior: 'smooth'
            }}
            >
                <Header activeMenu="3" />
                <div className={`${styles.page} flex-col indexResponse_page__22n8A`}>
                    <div className="">
                        <div className="dv_main_contentwrap">
                            <div className="flex-col">
                                <div className='flex-row universities-filter_filter_buttons__dsksN align-items-center'>
                                    <label className='filter_filter_by__Tttrf'>国家:</label>
                                    <ul className='dv_select_country_wrap'>
                                        <li className={`clickable ${!this.state.filterCountry ? 'choose' : ''}`} onClick={() => {
                                            this.setState({ 'filterCountry': '' })
                                        }}>全部</li>{
                                            universitiesList.map(item => {
                                                return (<li className={`clickable ${this.state.filterCountry == item.country_code ? 'choose' : ''}`} onClick={() => {
                                                    this.setState({ 'filterCountry': item.country_code })
                                                }}>{item.country}</li>)
                                            })
                                        }</ul></div>
                                <div className="dv_list_item_wrap">
                                    {data_list.filter(a => {
                                        return (!this.state.filterCountry || this.state.filterCountry == a.country_code);
                                    }).map((item, index) => {
                                        return (
                                            <div className="clickable  flex-col dv_unvierst_item" >
                                                <div class="university-card_uni_card_top__eMRzY" style={{ backgroundColor: `${this.state.colors[index % 5]}` }}></div>
                                                < div className="flex-col">
                                                    <div className='dv_bg_guojia'>
                                                        <img src={require("./assets/img/unviserty/" + item.background_image)} />
                                                    </div>
                                                    <div className='dv_item_wrapintroud'>
                                                        <div className="text_itemname">{item.name}</div>
                                                        <div className='dv_intoudction'>{item.introduction}</div>
                                                        <div class="align-items-end d-flex justify-content-between mt-auto">
                                                            <div class="university-card_uni_card_footer__4FmR_"></div>
                                                            <div class="university-card_uni_card_pill_wrapper__sMk6Z justify-items-end flex-row">
                                                                <div class="country-pill_pill__oEbGd country-pill d-flex flex-row" aria-label="university located in UK">
                                                                    <img src={require("./assets/img/flag/" + item.country_code + ".svg")} height="15" width="15" alt="Flag of the United Kingdom" />
                                                                    <span class="country-pill_pill_label__AzqRb align-items-center">{item.country}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>)
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                <Footer />
            </div >
        );
    }
}

export { Universities, universitiesList };