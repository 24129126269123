'use strict';

import React, { Component } from 'react';
import CarouselImg from './carousel.jsx';
import styles from './assets/indexResponse.module.css';

const print = function (value) { };
class DDS_lanhupage_0 extends Component {
  state = {};
  setValue(val) { }
  render() {
    return (
      <div className={`${styles['box_6']} flex-col indexResponse_box_6__2xis9`}>
        <div className={`${styles['section_5']} flex-col indexResponse_section_5__2zolh`} />
        <span className={`${styles['text_13']} bold`}>留学英国</span>
        <div className={`${styles['text-group_11']} flex-col justify-between indexResponse_text-group_11__1ikns`}>
          <span className={`${styles['paragraph_1']}`}>
            每年都有成千上万的国际学生选择英国作为留学目的地。英国以卓越的学术水平而闻名，英国的学位在全球范围内得到广泛的认可——为你提供在全球范围内发展成功事业的机会。&nbsp;
            <br />
            从我们著名的合作大学中进行选择，或从INTO择校中心升读众多一流院校，享受个性化支持，助你找到最适合自己的大学。
          </span>
          <span className={`${styles['text_14']} bold`}>#学生为何热爱英国</span>
        </div>

        <CarouselImg ShowSlideUnit={3} totalSlides={4} marginLeftValue={1.25} wrapWidthVw={63.75} slideWrapperName="slideViewimg" mainContentBox="picslidewrap2">
          <div className={`${styles['section_6']} flex-row justify-between indexResponse_section_6__3tUq7 visible transition`} id="slideViewimg">
            <div className={`${styles['text-wrapper_7']} flex-col align-items-center indexResponse_text-wrapper_7__14hmd`}>
              <span className={`${styles['text_15']} marginLeft-0 indexResponse_text_15__1ciwB`}>顶尖名牌大学</span>
              <span className={`${styles['paragraph_2']} marginLeft-0 alignCenter`}>
                17所英国大学
                <br />
                全球排名前百
              </span>
            </div>
            <div className={`${styles['text-wrapper_8']} flex-col align-items-center indexResponse_text-wrapper_8__pfYlf`}>
              <span className={`${styles['paragraph_3']} marginLeft-0 alignCenter indexResponse_paragraph_3__1bb8t`}>
                较短学位时长
                <br />
                最大节省成本
              </span>
              <span className={`${styles['paragraph_4']} marginLeft-0 alignCenter`}>
                更早毕业
                <br />
                花费更少学费与生活费
              </span>
            </div>
            <div className={`${styles['text-wrapper_9']} flex-col align-items-center indexResponse_text-wrapper_9__1-JLG`}>
              <span className={`${styles['text_16']} marginLeft-0 indexResponse_text_16__2EEh`}>未来成功职业起点</span>
              <span className={`${styles['paragraph_5']} marginLeft-0 alignCenter`}>
                毕业后有机会
                <br />
                留英工作俩年
              </span>
            </div>
            <div className={`${styles['text-wrapper_9']} flex-col align-items-center indexResponse_text-wrapper_9__1-JLG`}>
              <span className={`${styles['text_16']} marginLeft-0 indexResponse_text_16__2EEh`}>花费更少</span>
              <span className={`${styles['paragraph_5']} marginLeft-0 alignCenter`}>
                较短学位时长
                <br />
                最大节省成本
              </span>
            </div>

          </div>
        </CarouselImg>

        <span className={`${styles['text_17']} bold indexResponse_text_17__3eJjM`}>#探索英国留学目的地</span>
        <span className={`${styles['text_18']}`}>点击下方板块了解关于我们学习中心和全英合作大学的更多信息。</span>
        <span className={`${styles['text_19']} bold`}>大学合作伙伴</span>
        <div className={`${styles['section_7']} flex-row justify-between`}>
          <div className={`${styles['text-wrapper_10']} flex-col indexResponse_text-wrapper_10__DwpF9 clickable`}>
            <span className={`${styles['text_20']}`}>英国伦敦</span>
          </div>
          <div className={`${styles['text-wrapper_11']} flex-col indexResponse_text-wrapper_11__mkUsQ clickable`}>
            <span className={`${styles['text_21']}`}>英格兰兰卡斯特</span>
          </div>
          <div className={`${styles['text-wrapper_12']} flex-col indexResponse_text-wrapper_12__1Hdmh clickable`}>
            <span className={`${styles['text_22']}`}>英格兰曼彻斯特</span>
          </div>
        </div>
        <div className={`${styles['list_3']} flex-row`}>
          <div className={`${styles['text-wrapper_13-0']} flex-col indexResponse_text-wrapper_13-0__1Gbte clickable`}>
            <span className={`${styles['text_23-0']}`}>英格兰泰恩河畔纽卡斯尔</span>
          </div>
          <div className={`${styles['text-wrapper_13-1']} flex-col indexResponse_text-wrapper_13-1__2hqJC clickable`}>
            <span className={`${styles['text_23-1']}`}>北爱尔兰贝尔法斯特</span>
          </div>
          <div className={`${styles['text-wrapper_13-2']} flex-col indexResponse_text-wrapper_13-2__tq2kD clickable`}>
            <span className={`${styles['text_23-2']}`}>英格兰诺里奇</span>
          </div>
        </div>
        {/* <span className={`${styles['text_24']} bold`}>择校中心</span>
        <div className={`${styles['section_8']} flex-row justify-between`}>
          <div className={`${styles['text-wrapper_14']} flex-col indexResponse_text-wrapper_14__3Pist clickable`}>
            <span className={`${styles['text_25']}`}>英国伦敦</span>
          </div>
          <div className={`${styles['text-wrapper_15']} flex-col indexResponse_text-wrapper_15__AJxgc clickable`}>
            <span className={`${styles['text_26']}`}>英国伦敦</span>
          </div>
          <div className={`${styles['text-wrapper_16']} flex-col indexResponse_text-wrapper_16__kvr_y clickable`}>
            <span className={`${styles['text_27']}`}>英格兰曼彻斯特</span>
          </div>
        </div> */}
        <div className={`${styles['text-group_12']} flex-col justify-between indexResponse_text-group_12__2lcYt`}>
          <span className={`${styles['text_28']} bold`}>#探索英国</span>
          <span className={`${styles['text_29']}`}>
            充分利用你在英国的时光探索这里的一切——从丰富的历史到多元化的城市，再到世界上最美丽的风景。
          </span>
        </div>
        <div className={`${styles['box_10']} flex-col`}>
          <div className={`${styles['text-wrapper_17']} flex-col`}>
            <span className={`${styles['text_30']}`}>伦敦牛津街</span>
            <span className={`${styles['text_31']}`}>世界最著名的购物街之一</span>
          </div>
        </div>
        <div>
          <CarouselImg ShowSlideUnit={5} totalSlides={15} marginLeftValue={1.61} wrapWidthVw={63.75} slideWrapperName="slidepicture" mainContentBox="slidewrap">
            {/* 第一屏 */}
            <div className={`${styles['section_9']} flex-row justify-between indexResponse_section_9__1Wpyf visible transition`} id="slidepicture">
              <div className={`${styles['group_2']} flex-col clickable`} />
              <div className={`${styles['group_3']} flex-col clickable`} />
              <div className={`${styles['group_4']} flex-col clickable indexResponse_group_4__pMj-z visible`}>
                <div className={`${styles['box_12']} flex-col indexResponse_box_12__1amav`} />
              </div>
              <div className={`${styles['group_5']} flex-col clickable`} />
              <div className={`${styles['group_6']} flex-col clickable`} />

              {/* 第二屏 */}
              <div className={`${styles['group_3']} flex-col clickable`} />
              <div className={`${styles['group_3']} flex-col clickable`} />
              <div className={`${styles['group_4']} flex-col clickable indexResponse_group_4__pMj-z visible`}>
                <div className={`${styles['box_12']} flex-col indexResponse_box_12__1amav`} />
              </div>
              <div className={`${styles['group_5']} flex-col clickable`} />
              <div className={`${styles['group_6']} flex-col clickable`} />
              {/* 第三屏 */}
              <div className={`${styles['group_3']} flex-col clickable`} />
              <div className={`${styles['group_3']} flex-col clickable`} />
              <div className={`${styles['group_4']} flex-col clickable indexResponse_group_4__pMj-z visible`}>
                <div className={`${styles['box_12']} flex-col indexResponse_box_12__1amav`} />
              </div>
              <div className={`${styles['group_5']} flex-col clickable`} />
              <div className={`${styles['group_6']} flex-col clickable`} />

            </div>
          </CarouselImg>
        </div>
        <div className={`${styles['box_13']} flex-col indexResponse_box_13__RXSEp`}>
          <span className={`${styles['text_32']} bold`}>准备好开始你的英国留学之旅了吗？</span>
          <div className={`${styles['group_7']} flex-row clickable`}>
            <div className={`${styles['image-text_9']} flex-row justify-between `}>
              <span className={`${styles['text-group_3']}`}>立即申请</span>
              <img
                className={`${styles['label_1']}`}
                src={
                  require('./assets/img/SketchPngd2349a5f07b00c012d52de23c774f9643242205efe55c5073ef96cda5a197319.png')
                }
              />
            </div>
          </div>
        </div>
        {/* <div className={`${styles['box_14']} flex-col`}>
          <div className={`${styles['text-wrapper_22']} flex-row`}>
            <span className={`${styles['paragraph_6']}`}>
              较短学位时长
              <br />
              最大节省成本
            </span>
          </div>
          <div className={`${styles['text-wrapper_23']} flex-row`}>
            <span className={`${styles['paragraph_7']}`}>
              更早毕业
              <br />
              花费更少学费与生活费
            </span>
          </div>
        </div> */}
      </div>
    );
  }
}
export default DDS_lanhupage_0;
