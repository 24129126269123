'use strict';

import React, { Component } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

import styles from './assets/countryMenuResponse.module.css';

class DDS_lanhupage_CountryMenu extends Component {
  state = {};
  setValue(val) { }
  render() {
    return (
      <div className='dv_countryMenu_wrap'>
        <div className={`${styles['section_10']} flex-col justify-between countryMenuResponse_section_10__RaVKI `}>

          <div className={this.props.activeMenu == 'liuxue' ? `${styles['text-wrapper_1']} countryMenuResponse_text-wrapper_1__1fOTp flex-col clickable active` : `${styles['text-wrapper_1']} flex-col clickable countryMenuResponse_text-wrapper_1__1fOTp`} onClick={() => { this.props.onActiveMenu('liuxue'); }}>
            <span className={"countryMenuResponse_text_7__1P9jR"} >海外留学</span>
          </div>
          <div className={this.props.activeMenu == 'uk' ? `${styles['text-wrapper_2']} flex-col clickable active` : `${styles['text-wrapper_2']} flex-col clickable`} onClick={() => this.props.onActiveMenu('uk')}>
            <span className={"countryMenuResponse_text_7__1P9jR"} >英国</span>
          </div>
          <div className={this.props.activeMenu == 'usa' ? `${styles['text-wrapper_3']} flex-col clickable active` : `${styles['text-wrapper_3']} flex-col clickable`} onClick={() => this.props.onActiveMenu('usa')}>
            <span className={"countryMenuResponse_text_7__1P9jR"} >美国</span>
          </div>
          <div className={this.props.activeMenu == 'au' ? `${styles['text-wrapper_4']} flex-col clickable active` : `${styles['text-wrapper_4']} flex-col clickable`} onClick={() => this.props.onActiveMenu('au')}>
            <span className={"countryMenuResponse_text_7__1P9jR"} >澳大利亚</span>
          </div>
          <div className={this.props.activeMenu == 'language' ? `${styles['text-wrapper_5']} flex-col clickable active` : `${styles['text-wrapper_5']} flex-col clickable`} onClick={() => this.props.onActiveMenu('language')}>
            <span className={"countryMenuResponse_text_7__1P9jR"} >语言</span>
          </div>
          <div className={this.props.activeMenu == 'culture' ? `${styles['text-wrapper_6']} flex-col clickable active` : `${styles['text-wrapper_6']} flex-col clickable`} onClick={() => this.props.onActiveMenu('culture')}>
            <span className={"countryMenuResponse_text_7__1P9jR"}>文化</span>
          </div>
        </div>
      </div>
    );
  }
}
export default DDS_lanhupage_CountryMenu;
