// ScrollContainer.js - 基类组件
import React from 'react';

import Header from './components/header.jsx';
import Footer from './components/footer.jsx';
import DDS_lanhupage_1 from './view/lanhu_11shouye/index.jsx';
import DDS_lanhupage_2 from './view/lanhu_12yishuyuke/index.jsx';
import DDS_lanhupage_3 from './view/lanhu_13dingxiangbenke/index.jsx';
import DDS_lanhupage_4 from './view/lanhu_14guojibenke/index.jsx';
import DDS_lanhupage_5 from './view/lanhu_15hezuoyuanxiao/index.jsx';


class Home extends React.Component {
    constructor(props) {
        super(props);
        this.scrollContainer = React.createRef();
        this.activeIndex = 0;
        this.state = { blockIndex: 0 }
    }

    componentDidMount() {
        // this.animateOnScroll();
        this.setupScrollHandler();



    }

    // 添加滚动事件监听
    setupScrollHandler = () => {
        this.scrollContainer.current.addEventListener('scroll', () => {
            const newIndex = Math.round(this.scrollContainer.current.scrollTop / window.innerHeight);
            console.log("当前屏幕---" + newIndex)
            if (newIndex !== this.activeIndex) {
                this.activeIndex = newIndex;
                // 触发自定义翻屏事件
                const event = new CustomEvent('HomeScreenChange', { detail: { index: newIndex } });
                this.setState({ blockIndex: newIndex })
                console.log(event);
                window.dispatchEvent(event);
            }
        });
    }

    // animateOnScroll = () => {
    //     const observer = new IntersectionObserver((entries) => {
    //         entries.forEach(entry => {
    //             // 强制重置动画
    //             if (entry.isIntersecting) {
    //                 entry.target.classList.remove('active');
    //                 void entry.target.offsetWidth; // 触发重绘
    //                 entry.target.classList.add('active');
    //             }
    //         });
    //     }, {
    //         threshold: 0.25,
    //         root: this.scrollContainer.current
    //     });

    //     document.querySelectorAll('.page-section').forEach(el => observer.observe(el));
    // }

    render() {
        return (
            <div id="mainwrap" ref={this.scrollContainer} style={{
                height: '100vh',
                overflowY: 'scroll',
                scrollSnapType: 'y mandatory',
                scrollBehavior: 'smooth',

            }}
            >
                <Header activeMenu="0" />
                <div style={{ height: '100vh', scrollSnapAlign: 'start' }}>
                    {this.state.blockIndex === 0 ? <DDS_lanhupage_1 onClickNextcurrentSLide={(value) => { this.setState({ blockIndex: value }) }} currentSlideIndex={this.state.blockIndex} /> : <div ></div>}
                </div>
                <div style={{ height: '100vh', scrollSnapAlign: 'start' }} id="step1">
                    {this.state.blockIndex === 1 ? <DDS_lanhupage_2 onClickNextcurrentSLide={(value) => { this.setState({ blockIndex: value }) }} currentSlideIndex={this.state.blockIndex} /> : <div ></div>}
                </div>
                <div style={{ height: '100vh', scrollSnapAlign: 'start' }} id="step2">
                    {this.state.blockIndex === 2 ? <DDS_lanhupage_3 onClickNextcurrentSLide={(value) => { this.setState({ blockIndex: value }) }} currentSlideIndex={this.state.blockIndex} /> : <div ></div>}
                </div>
                <div style={{ height: '100vh', scrollSnapAlign: 'start' }} id="step3">
                    {this.state.blockIndex === 3 ? <DDS_lanhupage_4 onClickNextcurrentSLide={(value) => { this.setState({ blockIndex: value }) }} currentSlideIndex={this.state.blockIndex} /> : <div ></div>}
                </div>
                <div style={{ height: '100vh', scrollSnapAlign: 'start', overflowY: "auto" }} className='dv_footer_bar_scroll'>
                    {this.state.blockIndex === 4 ? <DDS_lanhupage_5 onClickNextcurrentSLide={(value) => { this.setState({ blockIndex: value }) }} currentSlideIndex={this.state.blockIndex} /> : <div ></div>}
                    <Footer />
                </div>
            </div>
        );
    }
}

export default Home;