'use strict';

import React, { Component } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

import styles from './assets/indexResponse.module.css';
import { transform } from '@babel/core';

class CarouselImg extends Component {
  constructor(props) {
    super(props);

    this.state = {
      slideIndex: 1,
      ShowSlideUnit: this.props.ShowSlideUnit || 5,
      totalSlides: this.props.totalSlides || 15,
      sidesNumber: 1, //分成多少屏
      marginLeftValue: this.props.marginLeftValue || 1.61,//每个图片小块相隔左边距VW
      wrapWidthVw: this.props.wrapWidthVw || 63.75,  //容器的宽度vw值，换算成像素用
      slideWrapperName: this.props.slideWrapperName || 'slidepicture',
      mainContentBox: this.props.mainContentBox || 'slidewrap' //最外面的遮罩层
    }
  }
  componentDidMount() {
    var m = this.state.totalSlides;
    var n = this.state.ShowSlideUnit;
    var k = m % n == 0 ? parseInt(m / n) : parseInt(m / n) + 1;
    this.setState({ sidesNumber: k })
  }

  onPageNext = () => {
    var o = document.getElementById(this.state.slideWrapperName);

    var i = this.state.slideIndex;

    if (i < this.state.sidesNumber) {
      i = i + 1
      var k = parseInt(this.state.marginLeftValue * (o.clientWidth / this.state.wrapWidthVw)); //把1vw换算成像素
      var dic = -(i - 1) * (o.clientWidth + k);
      this.setState({ slideIndex: i })
      o.style.transform = "translateX(" + dic + "px)"
    }

  }
  onPagePrev = () => {
    var o = document.getElementById(this.state.slideWrapperName);
    var i = this.state.slideIndex;
    i = i - 1
    var k = parseInt(this.state.marginLeftValue * (o.clientWidth / this.state.wrapWidthVw));
    var dic = 0;
    if (i == 0) {
      dic = 0
    }
    else {
      dic = -(i - 1) * (o.clientWidth + k)
    }

    this.setState({ slideIndex: i })
    o.style.transform = "translateX(" + dic + "px)"


  }
  render() {
    return (
      <div className={`${this.state.mainContentBox} overhide`}>

        {this.props.children}


        <div className='flex-row  pageArrowWrap'>
          {this.state.slideIndex > 1 && <div className='page-prev clickable ' onClick={() => this.onPagePrev()}></div>}

          {this.state.slideIndex < this.state.sidesNumber && <div className='page-next clickable ' onClick={() => this.onPageNext()}></div>}
        </div>
      </div>
    );
  }
}
export default CarouselImg;
